import React, { useState } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const [fullName, setFullName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const onClickButton = (event) => {
    event.preventDefault();

    // Validate email before sending
    if (!isValidEmail) {
      console.error("Invalid email address");
      return;
    }

    const templateParams = {
      fullName,
      contactNumber,
      userEmail: email,
      message,
    };

    const serviceId = "service_v0wnuok";
    const templateId = "template_u43lj9k";
    const publicKey = "Ojs0-uDRWLtuB4Z6P";

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          // Clear the form after successful submission
          clearForm();
        },
        (error) => {
          console.error("Error sending email:", error);
        }
      );
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));

    setEmail(newEmail);
  };

  const clearForm = () => {
    // Reset all state variables to their initial values
    setFullName("");
    setContactNumber("");
    setEmail("");
    setMessage("");
    setIsValidEmail(true); // Reset email validation status
  };

  return (
    <section id="contact">
      <div className="my-5">
        <h1 className="text-center">Contact Us</h1>
      </div>

      <div className="container contact-div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
            <form>
              <div className="form-group">
                <label htmlFor="fullName">Enter Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Enter Your Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="form-group pt-3">
                <label htmlFor="contactNumber">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="contactNumber"
                  placeholder="+91-"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
              <div className="form-group pt-3">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                  id="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={handleEmailChange}
                />
                {!isValidEmail && (
                  <div className="invalid-feedback">Invalid email address</div>
                )}
              </div>

              <div className="form-group pt-3">
                <label htmlFor="message">Type your Message Here</label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="3"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              <div className="col-12 pt-3">
                <button
                  onClick={onClickButton}
                  className="btn btn-outline-primary"
                  type="button"
                >
                  Submit form
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
